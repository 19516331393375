import React from "react";
import "./About.css";
import LineHeader from "../../components/LineHeader";

const About = () => {
  return (
    <section className="section__padding scroll-scale" id="about">
      <h2>About Me</h2>
      <LineHeader />
      <div className="about">
        <div className="about-image">
          <img src="/images/rimon.png" alt="profile" />
          <div className="info-about1">
            <span>8+</span>
            <p>Years of Experience</p>
          </div>
          <div className="info-about2">
            <span>25+</span>
            <p>Project Complete</p>
          </div>
        </div>

        <div className="desc">
          <p>
            I am a passionate and detail-oriented Full Stack Developer with a
            strong foundation in computer science and hands-on experience in
            building modern, scalable web applications. Holding a Bachelor’s
            Degree in Computer Science and a Diploma in Software Development, I
            have honed my skills in both frontend and backend technologies,
            enabling me to deliver end-to-end solutions that meet user needs and
            business goals.
          </p>

          <p>
            Over the years, I have worked on a variety of projects, from
            e-commerce platforms and task management applications to SaaS resume
            builders. My expertise lies in using technologies like React.js,
            Next.js, Node.js, and PostgreSQL to create responsive,
            user-friendly, and high-performance applications. I am particularly
            skilled in integrating third-party APIs, such as Stripe for payment
            processing and Clerk for user authentication, to enhance
            functionality and security.
          </p>

          <p>
            Proficient in front-end and back-end development, I excel in HTML,
            CSS, and JavaScript, and have experience working with frameworks
            such as Node.js and React JS. I am highly adaptable and continuously
            stay updated with the latest industry trends and technologies to
            ensure the delivery of cutting-edge web applications.
          </p>
          <p>
            One of my key strengths is my ability to adapt to new technologies
            and frameworks quickly. For example, I have utilized modern UI
            libraries like shadcn UI and Tailwind CSS to design intuitive
            interfaces, while also leveraging tools like Prisma and Cloudinary
            to optimize database operations and media management. My experience
            spans both independent projects and collaborative team environments,
            where I have consistently delivered results on time and with a focus
            on quality.
          </p>
          <p>
            I take pride in my problem-solving abilities and my commitment to
            writing clean, maintainable code. Whether it’s debugging complex
            issues, optimizing application performance, or implementing secure
            authentication systems, I approach every challenge with a
            solutions-oriented mindset. My work on projects like the Home Meal
            Delivery and Restaurant Website and the SaaS Resume Builder has
            allowed me to refine my skills in full-stack development, database
            management, and responsive design.
          </p>
          <p>
            Beyond technical skills, I am a lifelong learner who is always
            exploring new tools and techniques to stay ahead in the
            ever-evolving tech industry. My educational background has provided
            me with a solid theoretical foundation, while my practical
            experience has equipped me with the ability to apply that knowledge
            effectively in real-world scenarios. I am particularly passionate
            about creating applications that not only solve problems but also
            provide a seamless and enjoyable user experience.
          </p>
          <p>
            I am excited to continue growing as a developer and contributing to
            innovative projects that make a difference. Whether it’s building
            dynamic web applications, optimizing backend systems, or
            collaborating with cross-functional teams, I am committed to
            delivering high-quality solutions that drive success. Let’s connect and create something amazing together!
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
