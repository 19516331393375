import React from 'react'
import "./Education.css"
import LineHeader from '../../components/LineHeader';

const Education = () => {
  return (
    <section className="section__padding education scroll-scale" id="education">
      <h2>Education</h2>
      <LineHeader />
      <div className="education-main">
        <div className="circle-line">
          <div className="circle"></div>
          <div className="line"></div>
          <div className="circle"></div>
          <div className="line"></div>
        </div>
        <div className="education-container">
          <div className="education1">
            <h4>Diploma of Software development</h4>
            <h5>2022-2024</h5>
            
                <p>
                  Graduated with a GPA of 3.94, demonstrating a strong commitment
                  to academic excellence and a passion for software development.
                </p>
              
                <p>
                  Gained in-depth knowledge of database design and management,
                  including tasks such as backup and recovery, security
                  implementation, and performance optimization.
                </p>
              
                <p>
                  Developed expertise in frontend development using HTML, CSS,
                  JavaScript, and modern frameworks like React.js to build dynamic
                  and responsive user interfaces.
                </p>
              
                <p>
                  Acquired hands-on experience in backend development using
                  Node.js and Express.js, along with database integration, to
                  create robust and scalable web applications.
                </p>
             
                <p>
                  Mastered object-oriented programming (OOP) principles using
                  Java, including encapsulation, inheritance, and polymorphism, to
                  design modular and reusable software solutions.
                </p>
              
                <p>
                  Applied theoretical knowledge to practical projects, enhancing
                  skills in full-stack development, data integrity, and efficient
                  data retrieval.
                </p>
              
          </div>
          <div className="education2">
            <h4>Bachelor of Computer science</h4>
            <h5 className="date">2002 - 2005</h5>
            
                <p>
                  Graduated with a GPA of 3.3, showcasing dedication to academic
                  success and a strong foundation in computer science principles.
                </p>
              
                <p>
                  Completed coursework in Operating Systems, gaining expertise in
                  process management, memory management, and file systems to
                  optimize computer resources.
                </p>
             
                <p>
                  Studied Computer Security, learning techniques to protect
                  systems and networks from unauthorized access and cyber threats.
                </p>
              
                <p>
                  Developed a solid understanding of Data Structures and
                  Algorithms, enabling efficient problem-solving and software
                  optimization.
                </p>
             
                <p>
                  Gained practical experience in Database Management, focusing on
                  designing, implementing, and maintaining relational databases.
                </p>
              
                <p>
                  Acquired a well-rounded education in computer science, equipping
                  me with the skills to tackle complex technical challenges in a
                  professional setting.
                </p>
              
          </div>
        </div>
      </div>
    </section>
  );
}

export default Education