import React, { useState } from "react";
import "./Projects.css";
import LineHeader from "../../components/LineHeader";
import ProjectList from "./ProjectList";
import projects  from "../../projects.json";

const projectsList = projects.projects;
const categories = [
  "All",
  "Web App",
  // "Mobile App",
  "eCommerce",
  "SaaS",
  // "FinTech",
  "Desktop App"
];

const Projects = () => {
    const [selectedCategory, setSelectedCategory] = useState("All");
    const filteredProjects = projectsList.filter(
    (project) =>
      selectedCategory === "All" || project.category === selectedCategory,
  );

  return (
    <section className="section__padding " id="projects">
      <h2>Latest Projects</h2>
      <LineHeader />
      {/* Category Filter */}
      <div className="category-filter">
        {categories.map((category) => (
          <button
            key={category}
            onClick={() => setSelectedCategory(category)}
            className={` ${
              selectedCategory === category ? "selected" : "unselected"
            }`}
          >
            {category}
          </button>
        ))}
      </div>
      <div className="project_number">
        <h3> Total: {filteredProjects.length}</h3>
      </div>
      <div className="projects">
        <div className="container">
          <div className="projects-gallery scroll-bottom">
            {filteredProjects.length > 0 &&
              filteredProjects.map((project, index) => (
                <ProjectList
                  key={index}
                  src={project.src}
                  alt={project.alt}
                  title={project.title}
                  description={project.description}
                  href={project.href}
                />
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
